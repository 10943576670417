const React = require("react")
// we need to override gatsby-plugin-styled-components
// because we're not using styled-components directly but our design system library
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-styled-components/src/gatsby-browser.js
const { StyleSheetManager } = require("@abtasty/pulsar-common-ui")

// eslint-disable-next-line react/prop-types,react/display-name
exports.wrapRootElement = ({ element }, pluginOptions) => (
  <StyleSheetManager
    disableVendorPrefixes={pluginOptions?.disableVendorPrefixes === true}
  >
    {element}
  </StyleSheetManager>
)
